import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-base text-gray-800`} /* デフォルトはtext-base */

  p {
    ${tw`mt-0 leading-loose`}
  }
  
  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`} /* lg以上でtext-3xl */
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`} /* lg以上でtext-2xl */
  }

  h3 {
    ${tw`text-lg font-bold mt-6 lg:text-xl`} /* lg以上でtext-xl */
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  /* 小さい画面用の調整 */
  ${tw`sm:text-sm md:text-base lg:text-lg`} /* smでtext-sm, mdでtext-base, lgでtext-lg */
`;


const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default ({ headingText = "CONOTY プライバシーポリシー" }) => {
  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h2>1. 個人情報の収集について</h2>
            <p>
              当社は、ユーザーの同意を得た上で、または法令で許可された範囲で、以下の情報を収集する場合があります。
            </p>
            <ul>
              <li>ユーザーの氏名、メールアドレス、生年月日、性別</li>
              <li>アバター画像等のプロフィール情報</li>
              <li>お知らせメール情報（タイトル、受信日時、送信者名、送信者メールアドレス、本文、添付ファイルなどメールに紐づく情報）</li>
              <li>コメント、日時、担当者情報</li>
              <li>ログ情報（アクセス日時、使用デバイス、使用OS、IPアドレス等）</li>
              <li>支払い情報（有料サービス利用時）</li>
              <li>利用端末情報</li>
              <li>本サービス内の情報へのアクセス履歴</li>
              <li>本サービス内の広告へのアクセス履歴及び当該広告からの行動履歴</li>
              <li>広告識別子（IDFA または Google Advertising ID 等）</li>
              <li>ユーザーエージェント</li>
              <li>IP アドレス</li>
              <li>クッキー情報</li>
              <li>GPS 情報（適用される場合）</li>
            </ul>

            <h2>2. 個人情報の利用について</h2>
            <p>当社は、以下の目的のためにユーザーの個人情報を利用します。</p>
            <ul>
              <li>アカウントの作成および認証</li>
              <li>本サービス内でのコメント機能、スケジュール管理、担当者指定機能の利用</li>
              <li>本サービスに関するお問い合わせやサポートへの対応</li>
              <li>マーケティングおよびプロモーションに関する通知</li>
              <li>本サービスに関する当社の規約等に違反する行為への対応</li>
              <li>本サービス向上を目的とした調査、分析、改善</li>
              <li>当社サービスや、広告主企業、提供先企業、その他当社関連企業のサービスや商品等に関する情報提供</li>
              <li>その他上記利用目的に準ずる目的のため</li>
            </ul>

            <h2>3. 個人情報の保管について</h2>
            <p>
              当社は、収集した個人情報を適切に管理し、情報の漏洩、紛失、不正アクセス等を防止するための合理的な対策を講じます。また、収集した個人情報は、法令で定められた期間、またはサービスの提供に必要な期間に限り保管されます。
            </p>

            <h2>4. 第三者提供について</h2>
            <p>当社は、ユーザーの個人情報を第三者に提供することはありません。ただし、以下の場合に限り、ユーザーの同意を得た上で第三者に情報を提供することがあります。</p>
            <ul>
              <li>法令に基づく場合</li>
              <li>ユーザーの生命、身体、財産の保護に必要な場合</li>
              <li>サービス提供に必要な業務委託先への提供（例: データ保管、支払い処理）</li>
              <li>その他、個人情報保護法その他の法令で認められる場合</li>
            </ul>

            <h2>5. クッキー（Cookie）の利用について</h2>
            <p>
              本アプリでは、サービスの利便性向上のため、クッキーを使用することがあります。クッキーは、個人を特定できる情報を含まず、ユーザーのブラウザ設定によって受け入れを拒否することが可能です。クッキーの利用を拒否する場合は、ブラウザの設定を変更することでいつでも無効にすることができます。具体的な方法については、各ブラウザのサポートページをご参照ください。
            </p>

            <h2>6. アクセス解析ツールについて</h2>
            <p>
              本アプリは、Google Analytics等のアクセス解析ツールを使用し、ユーザーのアプリ利用状況を分析しています。これにより収集されたデータは匿名化されており、個人を特定するものではありません。データは、当社サービスの改善や最適化を目的として使用され、一定期間保管された後、適切に処理されます。
            </p>

            <h2>7. ユーザーの権利について</h2>
            <p>
              ユーザーは、当社に対して自身の個人情報の開示、訂正、削除、利用停止を求める権利があります。これらの請求を希望する場合は、当社のサポート窓口にご連絡ください。
            </p>

            <h2>8. 未成年者の個人情報について</h2>
            <p>
              本アプリの利用は、16歳未満の未成年者は保護者の同意を得ることを前提としています。保護者の同意なしに16歳未満の個人情報が収集されたことが判明した場合、速やかにその情報を削除いたします。
            </p>

            <h2>9. プライバシーポリシーの変更について</h2>
            <p>
              当社は、本ポリシーの内容を随時見直し、変更することがあります。重要な変更がある場合には、本アプリ内または公式ウェブサイトを通じてユーザーに通知いたします。
            </p>

            <h2>10. お問い合わせ</h2>
            <p>
              本ポリシーに関するお問い合わせは、以下の窓口までご連絡ください。
            </p>
            <p>株式会社AINNA</p>
            <p>住所: 東京都港区赤坂1-1-17細川ビル5F</p>
            <p>メールアドレス: support(at)conoty.ainna.com</p>
            <p>※迷惑メール対策で上記の表示ををしています。</p>
            <p>※実際にメールいただく際は(at)の部分を@に変更してお送りください。</p>

            <h2>11. 管轄裁判所</h2>
            <p>
              本ポリシーに関する紛争が生じた場合、日本法を準拠法とし、東京地方裁判所を専属的合意管轄裁判所とします。
            </p>
            <br></br>
            <p>制定: 2024年10月08日</p>
          </Text>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};
