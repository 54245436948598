import React from "react";
import tw from "twin.macro";
import styled from "styled-components/macro"; // eslint-disable-line
import { Link } from "react-router-dom"; // react-router-domのLinkを使用

const Container = tw.div`relative bg-gray-200 mt-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-8 flex flex-col items-center`;

const Column = tw.div`md:w-full flex flex-col items-center my-4`;
const ColumnHeading = tw.h5`font-bold mb-4 text-center`;

// スタイルのカスタマイズ
const LinkList = styled.ul`
  ${tw`flex justify-center text-sm font-medium space-x-4`}
  @media (max-width: 640px) {  /* 640px以下のスマホサイズで適用 */
    ${tw`flex-col space-x-0 space-y-2`}  /* 縦に並べる、上下に間隔を追加 */
  }
`;

const LinkListItem = styled.li`
  @media (max-width: 640px) {
    ${tw`text-center`}  /* スマホ画面ではセンター揃え */
  }
`;

const StyledLink = tw(Link)`
  border-b-0  // アンダーラインを削除
  text-gray-800  // 通常時のテキスト色
  hover:text-orange-500  // ホバー時にオレンジ系の色に変更
  transition duration-300  // スムーズなトランジション
`;

const Copyright = tw.p`text-center mt-4 text-sm text-gray-600 pb-8`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <Column>
          <ColumnHeading>ユーザーサポート</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <StyledLink to="/user-support">ユーザーサポートコラム</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/faqs">FAQs</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/Maintenance">メンテナンス</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/contact-us">お問い合わせ</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>規約</ColumnHeading>
          <LinkList>
          <LinkListItem>
              <StyledLink to="/terms-of-service">利用規約</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/terms-of-service-premium">プレミアム利用規約</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/privacy-policy">プライバシーポリシー</StyledLink> {/* プライバシーボタン */}
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/legal-notice">特定商取引法に基づく表示</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>運営会社</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <StyledLink to="/about-us">株式会社AINNA</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <Copyright>Copyright © CONOTY by AINNA. All rights reserved</Copyright>
    </Container>
  );
};
