import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import conotyLogo from "images/conoty-lp-top/conoty-logo-wide.png"; // ロゴ画像のインポート
import appStoreBadge from "images/conoty-lp-body/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg";
import googlePlayBadge from "images/conoty-lp-body/GetItOnGooglePlay_Badge_Web_color_Japanese.png";

const HeadingInfoContainer = styled.div`
  ${tw`flex flex-col items-center`}
  margin: 40px 0; // 上下のマージンを40pxに設定
  padding: 0 16px; // 上下のマージンを40pxに設定
`;

const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const BadgeContainer = tw.div`flex mt-6`;
const AppStoreBadge = tw.img`w-48 mr-4`;
const GooglePlayBadge = tw.img`w-48`;
const Logo = tw.img`w-64`; // ロゴ画像のスタイル

const ReleaseText = styled.p`
  ${tw`text-center mt-4`}
  font-size: 1.5rem; // テキストサイズを大きく
  font-weight: bold; // 太字に設定
  color: #4a5568; // テキストカラーを設定（必要に応じて変更）
`;

const DownloadButtons = () => (
  <BadgeContainer>
    <AppStoreBadge src={appStoreBadge} alt="Download on the App Store" />
    <GooglePlayBadge src={googlePlayBadge} alt="Get it on Google Play" />
  </BadgeContainer>
);

const TopMessage = () => {
  return (
    <HeadingInfoContainer>
      <Logo src={conotyLogo} alt="CONOTY Logo" />  {/* ロゴ画像を表示 */}
      <HeadingDescription>
        お知らせを受け取って、大切な人と一緒に管理する新しいアプリです
      </HeadingDescription>
      <HeadingDescription>
        CONOTYは、お知らせを受け取り、管理するお知らせ専用アプリです。受け取ったお知らせについてアプリ上で相談できたり、添付されているファイルや開催予定、対応者など簡単に設定、発見することができます。
      </HeadingDescription>
      {/* アプリのダウンロードボタンを表示非表示する場合は、以下の行をコメントアウト/コメント解除してください */}
      {/* <DownloadButtons /> */}
      <ReleaseText>
        2024年秋リリース予定
      </ReleaseText>
    </HeadingInfoContainer>
  );
};

export default TopMessage;
