import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {
  NavLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "./light.js";

import logoImage from "images/conoty-lp-top/conoty-logo-wide.png";

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto`}
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: opacity 0.6s ease-in-out;
  opacity: ${props => props.visible ? 1 : 0};
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
`;

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  padding: 0 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-800 hover:border-gray-900 hover:text-gray-900`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-800 hover:text-primary-500`}
  }
`;

const LogoContainer = styled.div`
  ${tw`flex items-center h-full`}
  max-width: 250px; 
`;

const Logo = styled.img`
  max-height: 40px !important; 
  width: auto !important;
  ${tw`object-contain`}
`;

const HeaderComponent = ({ links }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 100 && !visible) {
        setVisible(true);
      } else if (currentScrollPos <= 100 && visible) {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visible]);

  return (
    <StyledHeader visible={visible}>
      <LogoContainer>
        <LogoLink href="/">
          <Logo src={logoImage} alt="Conoty Logo" />
        </LogoLink>
      </LogoContainer>
      <DesktopNavLinks>
        {links}
      </DesktopNavLinks>
      <NavToggle />
    </StyledHeader>
  );
};

export default HeaderComponent;
