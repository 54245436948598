import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Prepare from "components/preparing/Prepare";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";


export default () => {
    return (
        <>
          <Header />
          <div>
            <Prepare />
          </div>
          <Footer />
        </>
      );
    };
    