import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header-top";
import Footer from "components/footers/lp-footer";

import AboutUsTopImage from "images/conoty-lp-about-us/about-us-top.png";
import AinnaLogo from "images/conoty-lp-about-us/ainna-logo-wh.png";

// メインの画像スタイル
const TopImage = tw.img`w-full h-auto`;

// ロゴ画像のスタイルとアニメーション
const LogoImage = styled.img`
  position: absolute;
  top: 50%;  // 親要素の中央に配置
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: fadeIn 1s ease-in-out 1s forwards; // 1秒遅れでフェードイン

  @media (min-width: 1024px) {
    width: 32%;
  }

  @media (max-width: 1023px) {
    width: 56%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// 画像をラップするコンテナ
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

// テキストセクションのスタイル
const TextSection = styled.div`
  background-color: #f7f7f7; // 薄いグレー
  color: #333; // 濃いグレー
  text-align: center; // 中央揃え
  padding: 40px 20px;
  
  p {
    font-size: 14px;
    margin: 4px 0; // タイトルと段落の間隔を適切に
    line-height: 1.6;
  }
`;

// メッセージセクションのスタイル
const MessageSection = styled.div`
  background-color: #fff; // 白い背景
  color: #333; // 濃いグレー
  text-align: center; // 中央揃え
  padding: 40px 20px;
  
  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
  }
`;

export default () => {
  return (
    <>
      <Header />
      <ImageContainer>
        <TopImage src={AboutUsTopImage} alt="About Us Top" />
        <LogoImage src={AinnaLogo} alt="Ainna Logo" />
      </ImageContainer>

      {/* テキストセクション */}
      <TextSection>
        <p>About us</p>
        <p>---</p>
        <p>&nbsp;</p>
        <p>株式会社AINNA</p>
        <p>AINNA Co., Ltd.</p>
        <p>&nbsp;</p>
        <p>代表者</p>
        <p>代表取締役 石井 健吾</p>
        <p>&nbsp;</p>
        <p>住所</p>
        <p>東京都港区赤坂1-1-17細川ビル5階</p>
        <p>&nbsp;</p>
        <p>設立</p>
        <p>2019年10月8日</p>
        <p>&nbsp;</p>
        <p>事業概要</p>
        <p>「CONOTY」の開発・運営</p>
        <p>Webプロモーションコンサル・代行</p>
        <p>Webアプリの開発・運営</p>
        <p>化粧品・美容関連商品の輸入・製造および販売</p>
        <p>&nbsp;</p>
        <p>主要取引金融機関</p>
        <p>三菱UFJ銀行</p>
        <p>&nbsp;</p>
      </TextSection>
      <MessageSection>
        <p>Message</p>
        <p>---</p>
        <p>&nbsp;</p>
        <p>愛を込めて、あなたに</p>
        <p>AINNA</p>
        <p>&nbsp;</p>
        <p>「明日を楽しみに眠れる毎日を」お届けすること</p>
        <p>それが、私たちAINNAの大切にしている想いです</p>
        <p>&nbsp;</p>
        <p>私たちは、サービスや製品を手に取る</p>
        <p>すべての人々のことを思い描き</p>
        <p>常に「どうすれば喜んで使っていただけるか」</p>
        <p>を第一に考えています</p>
        <p>&nbsp;</p>
        <p>使う人々の生き方に貢献できる</p>
        <p>本当に価値あるサービスや製品をお届けしたい</p>
        <p>それがAINNAの使命です</p>
        <p>&nbsp;</p>
        <p>私たちの提供するサービスや製品が</p>
        <p>すべての皆さまの「パートナー」となれるよう</p>
        <p>日々努力を重ねています</p>
        <p>&nbsp;</p>
        <p>AINNAは、常にお客様の幸せを最優先に</p>
        <p>これからも進化を続けてまいります</p>
        <p>どうぞご期待ください</p>
        <p>&nbsp;</p>
        <p>株式会社AINNA 代表取締役</p>
        <p>石井 健吾</p>
      </MessageSection>

      <Footer />
    </>
  );
};
