import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PolicyText = styled.div`
  ${tw`text-base text-gray-800`} /* デフォルトはtext-base */

  p {
    ${tw`mt-0 leading-loose`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`} /* lg以上でtext-2xl */
  }

  /* 小さい画面用の調整 */
  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default ({ headingText = "CONOTY Premium利用規約" }) => {
  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <PolicyText>
            <h2>第1条 CONOTY Premium利用規約について</h2>
            <p>
              CONOTY Premium利用規約（以下「本規約」）は、株式会社AINNA（以下「当社」）が提供する定額制サービス「CONOTY Premium」（以下「本サービス」）の利用条件および当社と利用者との間の権利義務関係を定めるものであり、本サービスを利用する際に適用されます。利用者は、本規約に同意したものとみなされます。
            </p>
            <h2>第2条 定義</h2>
            <p>本規約において使用する用語の定義は、別段定めがない限り、「CONOTY利用規約」（以下「基本利用規約」）の定義に従うものとします。</p>
            <h2>第3条 利用料金</h2>
            <p>
              CONOTY Premiumの利用料金は、購入時に表示される購読申し込みページに記載された料金に従います。当社は、プレミアム会員の事前承諾を得ることなく、当社の裁量により将来に向かって利用料金を変更することがあります。プレミアム会員はこれに同意するものとします。
            </p>
            <h2>第4条 決済</h2>
            <p>
              未成年の利用者が決済を行う場合、保護者または親権者の同意を得た上で行うものとします。利用料金の支払いは、当社が指定する決済手段によって行われ、サービス期間が終了するごとに自動的に更新されます。更新時に再度決済が行われ、利用者は当社が指定する決済手段により利用料金を支払うものとします。当社は、領収書等の発行は行わないものとします。
            </p>
            <h2>第5条 解約</h2>
            <p>
              プレミアム会員は、CONOTY Premiumの解約を希望する場合、当社所定の手続きに従って解約手続きを行うものとします。解約手続きが完了した時点で、CONOTY Premiumの利用は終了したものとみなします。なお、すでに受領した利用料金の返金は一切行いません。月の途中で解約手続きを行った場合でも、当月分の利用料金は全額支払うものとします。
            </p>
            <h2>第6条 有料登録の解除</h2>
            <p>
              当社は、プレミアム会員が以下のいずれかに該当する場合、事前通知なしにCONOTY Premiumの利用を一時停止または契約を解除することができます。
            </p>
            <ul>
              <li>基本利用規約に違反した場合</li>
              <li>利用料金の支払いが滞った場合</li>
              <li>その他、当社がプレミアム会員として不適切と判断した場合</li>
            </ul>
            <p>解除に伴う損害について、当社は一切の責任を負わないものとします。</p>
            <h2>第7条 サービスの変更・停止・終了</h2>
            <p>
              当社は、予告なくCONOTY Premiumの内容を変更、停止、終了することができ、またコンテンツ等を削除することがあります。
            </p>
            <h2>第8条 免責</h2>
            <p>
              当社は、CONOTY Premiumを含む本サービスの動作保証を行わず、いかなる責任も負わないものとします。本サービスの利用に関連して発生した損害について、当社の故意または重大な過失による場合を除き、当社が負う損害賠償額は、利用者が当該サービス利用期間に支払った利用料金の範囲を超えないものとします。
            </p>
            <p>&nbsp;</p>
            <p>附則</p>
            <p>2024年9月1日制定</p>
          </PolicyText>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};
