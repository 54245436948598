import React from "react";
import Header from "components/headers/lp-header-top";
import Top from "components/hero/lp-top";
import TopMessage from "components/features/lp-top-message";
import Features from "components/features/lp-body";
import Footer from "components/footers/lp-footer";

export default () => (
  <>
    <Header />
    <Top />
    <TopMessage />
    <Features />
    <Footer />
  </>
);
