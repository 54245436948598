import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import ContactUsForm from "components/forms/ContactForm";


export default () => {
  return (
    <>
      <Header />
      <ContactUsForm />
      <Footer />
    </>
  );
};
