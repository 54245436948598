import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";
import { Link } from "react-router-dom"; // react-router-domのLinkを使用

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-base text-gray-800`} /* デフォルトはtext-base */

  p {
    ${tw`mt-0 leading-loose`}
  }

  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`} /* lg以上でtext-3xl */
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`} /* lg以上でtext-2xl */
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  /* 小さい画面用の調整 */
  ${tw`sm:text-sm md:text-base lg:text-lg`} /* smでtext-sm, mdでtext-base, lgでtext-lg */
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

const StyledLink = tw(Link)`
  border-b-0  // 通常時はアンダーラインを非表示
  text-orange-500  // 通常時のテキスト色
  hover:text-orange-500  // ホバー時のテキスト色
  hover:border-b-2  // ホバー時にアンダーラインを表示
  hover:border-orange-500  // ホバー時のアンダーラインの色をオレンジに
  transition duration-300  // スムーズなトランジション
`;

const StyledAnchor = tw.a`
  border-b-0  // 通常時はアンダーラインを非表示
  text-orange-500  // 通常時のテキスト色
  hover:text-orange-500  // ホバー時のテキスト色
  hover:border-b-2  // ホバー時にアンダーラインを表示
  hover:border-orange-500  // ホバー時のアンダーラインの色をオレンジに
  transition duration-300  // スムーズなトランジション
`;



export default ({ headingText = "特定商取引法に基づく表示" }) => {
  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h2>事業者名</h2>
            <p>株式会社AINNA</p>

            <h2>所在地</h2>
            <p>東京都港区赤坂1-1-17細川ビル5F</p>

            <h2>代表者名</h2>
            <p>代表取締役 石井 健吾</p>

            <h2>販売価格</h2>
            <p>サービス紹介画面の価格サイトをご参照ください。</p>

            <h2>販売価格以外に発生する料金</h2>
            <p>アプリのダウンロード、サービスの利用、お問い合わせ等において、インターネット利用時の通信料が発生します。</p>

            <h2>決済方法</h2>
            <p>月額単位でのお支払いになります。決済方法や決済および支払時期はApple社またはGoogle社が定める規定に従います。</p>

            <h2>サービス開始時期</h2>
            <p>当社および当サービス所定の手続き後、すぐに利用いただけます。</p>

            <h2>サービス解約</h2>
            <p>月額のサブスクリプションサービスの解約のお申し出はいつでも可能です。なお、解約時期につきましては、解約した時点におけるサブスクリプションサービスの契約期間満了日になります。</p>
            <p>契約期間満了日はサブスクリプションサービスをお申し込みいただいた日から起算して1ヶ月経過した日になります。契約を更新された際は、更新日から起算して1ヶ月経過した日になります。</p>
            <p>サブスクリプションサービスの解約方法につきましてはお使いのスマートフォンのOS別に下記サイトにて確認ください。</p>
            <ul>
              <li>iOSの場合: <StyledAnchor href="https://support.apple.com/ja-jp/HT202039" target="_blank" rel="noopener noreferrer">Apple のサブスクリプションを解約する必要がある場合</StyledAnchor></li>
              <li>Androidの場合: <StyledAnchor href="https://support.google.com/googleplay/answer/7018481" target="_blank" rel="noopener noreferrer">Google Play での定期購入の解約、一時停止、変更</StyledAnchor></li>
            </ul>
            
            <h2>返品についての特約事項</h2>
            <p>商品の性質上、返品はお受けできません。</p>

            <h2>連絡先</h2>
            <p>
              <StyledLink to="/contact-us">こちら</StyledLink>のお問い合わせサイトより問い合わせください。
            </p>

            <h2>その他</h2>
            <p>・本サービスは、特定商取引法に規定されるクーリングオフは適用されません。</p>
            <p>・アプリやサービスに契約不適合が発見された場合でも、当社は一切の責任を負いません。なお、当該不適合箇所につきましてはこれを補修したアプリやサービスのバージョンアップ等を含む適切な方法にて対応いたします。</p>
          </Text>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};
