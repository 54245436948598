import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PolicyText = styled.div`
  ${tw`text-base text-gray-800`} /* デフォルトはtext-base */

  p {
    ${tw`mt-0 leading-loose`}
  }
  
  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`} /* lg以上でtext-3xl */
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`} /* lg以上でtext-2xl */
  }

  h3 {
    ${tw`text-lg font-bold mt-6 lg:text-xl`} /* lg以上でtext-xl */
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  /* 小さい画面用の調整 */
  ${tw`sm:text-sm md:text-base lg:text-lg`} /* smでtext-sm, mdでtext-base, lgでtext-lg */
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default ({ headingText = "CONOTY 利用規約" }) => {
  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <PolicyText>
            <h2>第1条（適用）</h2>
            <p>
              1. 本規約は、本サービスの提供条件および本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めるものであり、本サービスを利用するユーザーに適用されます。
            </p>
            <p>
              2. 当社が当社ウェブサイトまたはアプリケーションに掲載する本サービスの利用に関するルール、注意事項（以下「ルール等」といいます）は、本規約の一部を構成します。
            </p>
            <p>3. 本規約とルール等が矛盾する場合、当社が別途定める場合を除き、本規約が優先されます。</p>
            <p>4. 16歳未満の利用者は、本サービスの利用に際して保護者の同意が必要です。</p>

            <h2>第2条（定義）</h2>
            <p>
              1. 「当社ウェブサイト」とは、ドメインが「conoty.com」「conotyapp.com」である、当社が運営するウェブサイトをいいます。
            </p>
            <p>
              2. 「当社アプリケーション」とは、Apple Inc.が運営する「App Store」およびGoogle Inc.が運営する「Google Play」上で配布されるスマートフォン向けアプリケーション「CONOTY」を指します。
            </p>

            <h2>第3条（サービスの概要）</h2>
            <p>
              1. 本サービスの機能は、当社が自由に定めることができ、ユーザーに通知することなく変更される場合があります。
            </p>
            <p>
              2. ユーザーが本サービスの利用に必要な通信機器、通信手段および電力は、すべてユーザーの責任と費用負担で準備するものとします。
            </p>
            <p>
              3. 当社は、本サービスに当社または第三者の広告を掲載することができ、広告主との取引に関して当社は一切の責任を負いません。
            </p>

            <h2>第4条（登録）</h2>
            <p>
              1. 本サービスの利用を希望する者は、自己の責任と費用負担でユーザー登録を行い、当該登録をもってユーザーとなります。
            </p>

            <h2>第5条（利用停止および登録抹消）</h2>
            <p>
              1. 当社は、ユーザーが本規約に違反した場合、事前通知なしに利用停止または登録抹消措置を取ることができ、これにより生じた損害について当社は責任を負いません。
            </p>

            <h2>第6条（利用終了）</h2>
            <p>
              1. ユーザー登録の抹消または本アプリケーションのアンインストールにより、ユーザーの利用終了とみなされます。利用終了前にユーザーが負った義務は終了後も存続します。
            </p>

            <h2>第7条（ユーザーの責任）</h2>
            <p>1. ユーザーは自己の責任で本サービスを利用し、利用に伴う行為およびその結果について一切の責任を負います。</p>
            <p>
              2. ユーザーは、第三者の権利を侵害することなくコンテンツを投稿することを保証し、紛争が生じた場合、自己の費用と責任で解決するものとします。
            </p>
            <p>3. 当社は、ユーザーが投稿したコンテンツのバックアップを行う義務を負いません。</p>

            <h2>第8条（禁止事項）</h2>
            <p>以下の行為は禁じられます：</p>
            <ul>
              <li>本規約に違反する行為</li>
              <li>法令に違反する行為</li>
              <li>第三者の権利侵害</li>
            </ul>

            <h2>第9条（サービスの停止等）</h2>
            <p>
              当社は、サービスのメンテナンスまたは不可抗力による停止等、必要に応じて事前通知なしにサービスを停止できるものとします。
            </p>

            <h2>第10条（本サービスの変更、終了）</h2>
            <p>
              当社は、随時サービスを変更または終了することができます。
            </p>

            <h2>第11条（免責事項）</h2>
            <p>当社は以下の責任を負いません：</p>
            <ul>
              <li>ユーザーの期待する成果が得られない場合</li>
              <li>ユーザーによる損害の発生</li>
            </ul>

            <h2>第12条（権利帰属）</h2>
            <p>
              1. 本サービスに関する知的財産権は当社または正当な権利者に帰属します。
            </p>
            <p>
              2. ユーザーの投稿コンテンツに関する著作権はユーザーに帰属しますが、当社は無償で利用できるものとします。
            </p>

            <h2>第13条（個人情報の取扱い）</h2>
            <p>
              当社は、「プライバシーポリシー」に従って個人情報を取り扱います。
            </p>

            <h2>第14条（利用規約の変更）</h2>
            <p>
              当社は、必要に応じて本規約を変更することができ、変更後に本サービスを利用した場合、変更に同意したものとみなされます。
            </p>

            <h2>第15条（準拠法および裁判管轄）</h2>
            <p>
              本規約は日本法に準拠し、東京地方裁判所を専属的合意管轄裁判所とします。
            </p>

            <h2>第16条（利用者情報の取扱い）</h2>
            <p>
              1. 当社による利用者情報の取扱いについては、別途当社プライバシーポリシーに基づきます。ユーザーは、このプライバシーポリシーに従って当社がユーザーの情報を取り扱うことに同意するものとします。
            </p>
            <p>
              2. 当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として利用および公開することができます。
            </p>

            <h2>第17条（利用契約上の地位の譲渡等）</h2>
            <p>
              1. ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡、移転、その他の処分をすることはできません。
            </p>
            <p>
              2. 当社は、本サービスにかかる事業を他社に譲渡（事業譲渡、買収、合併、会社分割等を含む）した場合、当該事業譲渡に伴い、利用契約上の地位、本規約に基づく権利および義務ならびにユーザーの登録事項その他の顧客情報を譲受人に譲渡でき、ユーザーはかかる譲渡につき予め同意したものとみなします。
            </p>

            <p>&nbsp;</p>
            <p>附則</p>
            <p>2024年9月1日制定</p>
          </PolicyText>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};
