import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import messageImage1 from "images/conoty-lp-body/conoty-lp-1.png";
import messageImage2 from "images/conoty-lp-body/conoty-lp-2.png";
import messageImage3 from "images/conoty-lp-body/conoty-lp-3.png";
import messageImage4 from "images/conoty-lp-body/conoty-lp-4.png";
import messageImage5 from "images/conoty-lp-body/conoty-lp-5.png";
import messageImage6 from "images/conoty-lp-body/conoty-lp-6.png";
import messageImage7 from "images/conoty-lp-body/conoty-lp-7.png";

const Container = tw.div`relative`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0`;
const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-2 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full h-80 md:h-144 bg-contain bg-center bg-no-repeat`, 
  tw`mx-0 sm:mx-4 md:mx-8`, 
  tw`flex-shrink-0 md:w-7/12`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8 flex-grow`;

const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`lg:text-4xl text-2xl font-bold text-gray-900`;
const Description = tw.p`mt-2 sm:text-xl text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

export default () => {
  const cards = [
    {
      imageSrc: messageImage1,
      subtitle: "専用タイムライン",
      title: "大切なお知らせを専用タイムラインがお届け",
      description:
        "お知らせは全て、あなたと大切な方との専用タイムラインに届きます。大切なお知らせを見逃すことがありません。",
    },
    {
      imageSrc: messageImage2,
      subtitle: "コメント",
      title: "お知らせについてグループメンバーとコミュニケーション",
      description:
        "特定のお知らせに対して、グループメンバー間でコミュニケーションが取れ、全て記録に残るので、言った言わないのすれ違いもありません。",
    },
    {
      imageSrc: messageImage3,
      subtitle: "担当者登録",
      title: "お知らせに対して担当者を設定し、自身の担当を一覧表示",
      description:
        "対応が必要なお知らせについて、グループメンバーの誰が対応するかを設定することが可能です。自分が対応するとなっているものだけを一覧で確認できます。",
    },
    {
      imageSrc: messageImage4,
      subtitle: "添付ファイル検索",
      title: "お知らせに添付されたファイルだけを一覧表示し検索",
      description:
        "お知らせに添付されていたファイルだけを一覧で表示し、送信者や受信日などで検索することが可能です。「あのファイルどこだっけ？」と探す手間がなくなります。",
    },
    {
      imageSrc: messageImage5,
      subtitle: "カレンダー表示",
      title: "予定登録したお知らせはカレンダー形式で一覧表示",
      description:
        "お知らせの実施日や対応日をお知らせに対して設定することができます。設定されたものはカレンダーから探すことができるので、「週末何か予定あったっけ？」という時にすぐ見つけられます。",
    },
    {
      imageSrc: messageImage6,
      subtitle: "マルチグループ",
      title: "家族と、友人と、チームと様々なグループでお知らせを管理",
      description:
        "ご家族だけではなく、共通の趣味を持つご友人や、所属しているチームメンバーなどお好きなグループを作成いただくことが可能です。",
    },
    {
      imageSrc: messageImage7,
      subtitle: "ユーザーサポートコラム",
      title: "誰でも簡単に使えるようにサポートコラムを用意",
      description:
        "CONOTYの始め方、使い方をわかりやすくまとめたコラムを作成しました。CONOTYをこれからお使いいただく方だけではなく、家族や友人など一緒に使いたい方へオススメする際にも活用ください。",
      url: "https://conotyapp.com/user-support"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {card.url && (
                  <Link href={card.url} target="_blank" rel="noopener noreferrer">
                    ユーザーサポートコラムへ
                  </Link>
                )}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
